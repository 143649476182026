import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
    palette: {
        contrastThreshold: 3.1,
        tonalOffset: 0.07,
        primary: {
            light: "#42A5F5",
            main: "#005FCE",
            dark: "#0D47A1",
            contrastText: "#ffffff"
        },
        secondary: {
            light: "#e0f7fa",
            main: "#b2ebf2",
            dark: "#4dd0e1",
            contrastText: "#000000"
        },
        error: {
            light: "#ffde835",
            main: "#ffde835",
            dark: "#ffde835",
            contrastText: "#ffffff"
        }
    },
    background: "#860204",
    card: {
        background: "#e3e3e3"
    },
    section: {
        dark: {
            background: "#545454",
            color: "#ffffff"
        },
        padding: "40px",
        element: {
            background: "rgba(255,255,255,0.075)",
            borderRadius: "3px",
            boxShadow: "0px 2px 8px -5px black",

            child: {
                light: {
                    background: "rgba(255,255,255,0.1)",
                    backgroundHover: "rgba(255,255,255,0.05)"
                },
                dark: {

                }
            }
        }
    },
    typography: {
        useNextVariants: true
    }
})

export default theme