import React, { PureComponent, MouseEvent } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { throttle } from 'lodash'
import SVG from 'react-inlinesvg'
import Headroom from 'react-headroom'
import { WindowLocation } from '@reach/router'

import Theme from '../theme'
import TemporaryDrawer from './temporaryDrawer'
import Logo from '../images/strange-music-logo.svg'
import Dropdown from './dropdown/dropdown'
import 'typeface-roboto'

const LogoWrapper = styled.div`
  width: 80px;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  background: transparent;
  padding: 10px;

  @media(max-width: 768px) {
    width: 46px;
  }
`

const LogoTextWrapper = styled.div`
  margin: 0 20px;
  height: 100%;
`

const SVGWrapper = styled(SVG)`
  & {
    svg {
      width: 50px;
      height: 50px;
      fill: white;

      @media(max-width: 768px) {
        width: 35px;
        height: 35px;
      }
    }
  }
`

const navItems = [
  {
    id: 'nav-rates',
    name: 'Rates',
    href: '/rates',
    nestedItems: [],
  },
  {
    id: 'nav-about',
    name: 'About', 
    href: '/about',
    nestedItems: [
      {
        id: 'nav-about',
        name: 'Strange World',
        href: '/about'
      },
      {
        id: 'nav-about-brochure',
        name: 'Brochure',
        href: '/about#brochure'
      }
    ]
  }
]

type Props = {
  location: WindowLocation
}

type State = {
  isMobile: boolean,
  isServicesDropdownOpen: boolean,
  menuElement: EventTarget | undefined,
  atTop: boolean
}

export default class Header extends PureComponent<Props, State> {
  constructor(props: {}) {
    super(props)

    this.resize = throttle(this.resize.bind(this), 200)
    this.checkScrollPosition = throttle(this.checkScrollPosition.bind(this, 200))
    this.handleHover = this.handleHover.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.state = {
      isMobile: true,
      isServicesDropdownOpen: false,
      menuElement: undefined,
      atTop: true
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.resize()

    window.addEventListener('scroll', this.checkScrollPosition)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('scroll', this.checkScrollPosition)
  }

  resize() {
    if (window.innerWidth <= 768) {
      this.setState({
        isMobile: true
      })
    } else {
      this.setState({
        isMobile: false
      })
    }
  }

  checkScrollPosition() {
    if (window.visualViewport.pageTop < 100) {
      this.setState({
        atTop: true
      })
    } else {
      this.setState({
        atTop: false
      })
    }
  }

  handleHover = (event: MouseEvent) : void => {
    this.setState({
      isServicesDropdownOpen: true,
      menuElement: event.currentTarget
    })
  }

  handleClose = () : void => {
    this.setState({
      isServicesDropdownOpen: false,
      menuElement: undefined
    })
  }

  render() {    
    const navigation = this.state.isMobile ? (
      <TemporaryDrawer items={navItems} contractAllOnExit={true} expandAllOnOpen={true} />
    ) : (
      <Dropdown items={navItems} />
    )

    const headroomStyle = /\/|\/?gallery|rates|about\/?/.test(this.props.location.pathname) ? {
      display: 'flex',
      height: this.state.isMobile ? '56px' : '100px',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: Theme.palette.primary.dark,
      zIndex: 99999999999,
      boxShadow: this.state.atTop ? 'none' : '0px 0px 10px -2px black',
      marginBottom: this.state.atTop ? '0' : '20px'
    } : {
      display: 'flex',
      height: this.state.isMobile ? '56px' : '100px',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: this.state.atTop ? 'transparent' : Theme.palette.primary.dark,
      zIndex: 99999999999,
      boxShadow: this.state.atTop ? 'none' : '0px 0px 10px -2px black',
      marginBottom: this.state.atTop ? '0' : '20px'
    }
    
    return (
      <div>
        <Headroom style={headroomStyle}>
          <LogoTextWrapper>
            <LogoWrapper>
              <Link
                to="/"
                style={{
                  color: 'white',
                  textDecoration: 'none',
                }}>
                <SVGWrapper id="nav-logo" src={Logo} />
              </Link>
            </LogoWrapper>
          </LogoTextWrapper>
          {navigation}
        </Headroom> 
      </div>
    )
  }
}