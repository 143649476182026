export const Business = Object.freeze({
    name: 'Strange World Merchandising',
    address: '217 NW Victoria Dr',
    city: 'Lees Summit',
    state: 'MO',
    zip: '64086',
    phoneNumber: '(816) 434-7001',
    email: 'info@strangeworldmerchandising.com',
    url: 'https://www.strangeworldmerchandising.com',
    facebook: 'https://www.facebook.com/Strange-World-Merchandising-947192375471453/',
    twitter: 'https://twitter.com/swmerchandising',
    instagram: 'https://www.instagram.com/swmerchandising/',
    youtube: 'https://www.youtube.com/channel/UC-dvR6WUFFnFkece5hXACuw'
})